body {
  margin: 0;
  padding:0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --primary:#FFC734;
  --secondary:#2C2E35;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'Outfit', sans-serif;

}
@tailwind base;
@tailwind components;
@tailwind utilities;